import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let clientID = localStorage.getItem('client');
        let changeReq = req.clone();
        if (clientID) {
            changeReq = req.clone({
                params: req.params.append('client_id', clientID)
            });
        }
        return next.handle(changeReq);
    }
}
