import { AsapBaseService } from 'asap-crud';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClienteService } from './client.service';
import { AsapClientPipe } from '../@core/pipes/asap-client.pipe';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable()
export class AuthService extends AsapBaseService {

    public params:any = null;
    public theme: any = {};

    constructor(
        public http: HttpClient,
        public client: ClienteService,
        public router: Router,
        public title: Title
    ) {
        super(http);
        this.basePath = 'api';
        this.entityName = 'v2'
    }

    public getUserLogged() {
        return this.getResources({router: 'client/user/authenticated'});
    }

    public verify() {
        return this.getResources({router: 'client/verifica'});
    }

    public setSessionToken(data): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const token = 'Bearer' + ' ' + data.token;
            localStorage.setItem('token', token);
            setTimeout(() => {
                this.getUserLogged().subscribe((response: any) => {
                    localStorage.setItem('client',response.data.client_id);
                    this.setClientTheme(response.data.client_id).then((path) => {
                        this.verify().subscribe(verifica => {
                            if (response.success === false) {
                                resolve(false);
                            } else {
                                localStorage.setItem('loggedUser', JSON.stringify(response));
                                resolve(true);
                            }
                        });                        
                    });

                }, err => reject(err));
            }, 1000);
        });
    }

    public setTheme(theme) {
        return new Promise<any>((resolve, reject) => {
            if (theme) {
                localStorage.setItem('config_color', JSON.stringify(theme));
            
                const dictionary = theme.config;
                
                if (dictionary) {
                    AsapClientPipe.setDictionary(dictionary);

                    const html = document.querySelector('html');

                    html.style.setProperty('--bg-client', AsapClientPipe.dictionary.bgClient);

                    html.style.setProperty('--main-color', AsapClientPipe.dictionary.mainColor);

                    html.style.setProperty('--bg-navbar', '#FFFFFF'/*AsapClientPipe.dictionary.bgNavbar*/);
                    html.style.setProperty('--link-navbar', AsapClientPipe.dictionary.bgNavbar/*AsapClientPipe.dictionary.linkNavbar*/);

                    html.style.setProperty('--bg-sidebar', AsapClientPipe.dictionary.bgNavbar/*AsapClientPipe.dictionary.bgSidebar*/);
                    html.style.setProperty('--link-sidebar', AsapClientPipe.dictionary.linkSidebar);

                    html.style.setProperty('--bg-footer', AsapClientPipe.dictionary.bgFooter);
                    html.style.setProperty('--link-footer', AsapClientPipe.dictionary.linkFooter);

                    html.style.setProperty('--bg-btn', AsapClientPipe.dictionary.bgBtn);
                    html.style.setProperty('--text-btn', AsapClientPipe.dictionary.textBtn);

                    // Não mexa nas variáveis do visualizador sem a permissão do Sérgio
                    // Quem mexer é corno!
                    html.style.setProperty('--bg-sidebar-viewer', AsapClientPipe.dictionary.bgSidebarViewer);
                    html.style.setProperty('--bg-btn-sidebar-viewer-active', AsapClientPipe.dictionary.bgBtnSidebarViewerActive);
                    html.style.setProperty('--bg-btn-sidebar-viewer-text-active', AsapClientPipe.dictionary.bgBtnSidebarViewerTextActive);
                    html.style.setProperty('--icon-sidebar-viewer', AsapClientPipe.dictionary.iconSidebarViewer);
                    html.style.setProperty('--link-sidebar-viewer', AsapClientPipe.dictionary.linkSidebarViewer);

                    html.style.setProperty('--bg-btn-viewer', AsapClientPipe.dictionary.bgBtnSidebarViewerActive);
                    html.style.setProperty('--text-btn-viewer', AsapClientPipe.dictionary.bgBtnSidebarViewerActive);
                    html.style.setProperty('--text-btn-viewer-active', AsapClientPipe.dictionary.bgBtnSidebarViewerTextActive);
                    html.style.setProperty('--icon-sidebar-viewer', AsapClientPipe.dictionary.iconSidebarViewer);

                    html.style.setProperty('--primary-color', AsapClientPipe.dictionary.primaryColor);
                    html.style.setProperty('--primary-color-hover', AsapClientPipe.dictionary.primaryColorHover);

                    html.style.setProperty('--app-logo', AsapClientPipe.dictionary.logoDark);

                    // Guardando váriavel de configuração de Linguagem
                    localStorage.setItem('language', AsapClientPipe.dictionary.language);
                    // tslint:disable-next-line: max-line-length
                    localStorage.setItem('imageURL', `https://ui-avatars.com/api/?background=${AsapClientPipe.dictionary.bgBtn}&color=${AsapClientPipe.dictionary.textBtn}&size=400&bold=true&name=`)

                    setTimeout(() => {
                        this.title.setTitle(AsapClientPipe.dictionary.appName);
                        document.getElementById('favicon').setAttribute('href', AsapClientPipe.dictionary.favicon);
                        this.theme = theme;
                        console.log('Theme', this.theme.theme);
                        this.theme.themeLoaded = true; 
                        resolve(null);                     
                    }, 100);
                } 
                else {
                    setTimeout(() => {
                        this.title.setTitle('Cliente não Encontrado!');
                        this.theme.themeLoaded = true;
                        this.theme.clientNotFound = true;
                        this.router.navigate(['/not-found']);
                        resolve(null);
                    }, 100);
                }
            }
            else {
                setTimeout(() => {
                    this.title.setTitle('Cliente não Encontrado!');
                    this.theme.themeLoaded = true;
                    this.theme.clientNotFound = true;
                    this.router.navigate(['/not-found']);
                    resolve(null);
                }, 100);
            }
        });
    }

    public setClientTheme(clientID) {
        return new Promise<any>((resolve) => {
            this.client.getColorByID(clientID).subscribe((response: any) => {
                this.setTheme(response.data).then(() => {
                    resolve(true);
                })
            });
        });
    }

}