<ng-container *ngIf="data?.folks">

<h2 mat-dialog-title class="text-center">Curriculo de {{ data?.folks?.nome }}</h2>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="col-sm-12">
                <div class="curriculum-title">
                    <h3>Dados Pessoais</h3>
                </div>
                <div class="row curriculum-content">
                    <div class="col-sm-12" *ngIf="data.folks.profissao"><h4>{{data?.folks?.profissao | uppercase}}</h4></div>
                    <div class="col-sm-6"  *ngIf="data.folks.telefone"><i class="fa fa-phone mt-2 mr-2"></i> {{data?.folks?.telefone | mask: '(00) 00000-0000'}}</div>
                    <div class="col-sm-6"  *ngIf="data.folks.whatsapp"><i class="fa fa-whatsapp mt-2 mr-2"></i> {{data?.folks?.whatsapp | mask: '(00) 00000-0000'}}</div>
                    <div class="col-sm-6"  *ngIf="data.folks.email"><i class="fa fa-envelope mt-2 mr-2"></i> {{data?.folks?.email }}</div>
                    <div class="col-sm-6"  *ngIf="data.folks.url" ><i class="fa fa-link mt-2 mr-2"></i> {{data?.folks?.url }}</div>
                    <div class="col-sm-6"  *ngIf="data.folks.estado && data?.folks?.cidade"><i class="fa fa-map-marker mt-2 mr-2"></i> {{data?.folks?.estado}} / {{data?.folks?.cidade}}</div>
                    <div class="col-sm-6"  *ngIf="data.folks.instagram"><i class="fa fa-instagram mt-2 mr-2"></i> {{data?.folks?.instagram}}</div>
                    <div class="col-sm-6"  *ngIf="data.folks.facebook"><i class="fa fa-facebook mt-2 mr-2"></i>  {{data?.folks?.facebook}}</div>
                    <div class="col-sm-6 mt-2"  *ngIf="data.folks.idade"><span>Idade:</span>  {{data?.folks?.idade}}</div>
                    <div class="col-sm-12" *ngIf="data.folks.endereco"><i class="fa fa-map-marker mt-2 mr-2"></i> {{data?.folks?.endereco}}</div>
                    <div class="col-sm-12" *ngIf="data.folks.resumo"><i class="fa fa-link mt-2 mr-2"></i> {{data?.folks?.resumo}}</div>
                </div>
            </div>
            <div class="col-sm-12" *ngIf="data.folks.resumo">
            <div class="curriculum-title">
               <h3>OBJETIVO</h3>
            </div>
            <p></p>
            </div>
            <div class="col-sm-12 mt-3" *ngIf="data.video">
                <div class="curriculum-title">
                    <h3>Vídeo de apresentação</h3>
                </div>
                <div class="row curriculum-content">
                    <div class="col-sm-12" *ngIf="data.video">
                        <div class="creation-video-wrapper">
                            <video width="100%" height="300" controls>
                            <source [src]="data.video" type="video/mp4">
                            <source [src]="data.video" type="video/ogg">
                            Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                    <div class="col-sm-12" *ngIf="!data.video">
                        <p class="text-center mt-4 mb-3"><strong>Vídeo de apresentação não enviado!</strong></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mt-3" *ngIf="data.etalentData">
                <div class="curriculum-title">
                    <h3>Perfil Comportamental</h3>
                </div>
                <div class="row curriculum-content">
                    <div class="col-sm-12">
                        <h4>Principal talento</h4>
                        <h5 class="m-0 fs-38"><strong>{{ data.etalentData.behavioral_analysis['Talento'] }}</strong></h5>
                        <h4 class="m-0 mt-2 fs-20">Caracteristicas Predominantes</h4>
                        <h5 class="m-0 fs-19 mt-2"><strong>{{ data.etalentData.behavioral_analysis['Características predominantes'] }}</strong></h5>
                        <!-- <h5 class="m-0 fs-14 mt-4">Perfil completo <a style="color: var(--main-color)">aqui</a></h5> -->
                        <h5 class="m-0 fs-19">Ver análise do comportamento do candidato: <a (click)="openDialogEtalent(data.etalentData)"  style="cursor:pointer; color: var(--bg-btn, var(--bg-btn, #9ecc45));">clique aqui</a></h5>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mt-3" *ngIf="data.experience[0]?.cargo">
                <div class="curriculum-title">
                    <h3>Experiência</h3>
                </div>
                <div class="row curriculum-content" *ngFor="let experience of data?.experience">
                    <div class="col-sm-12">
                        <h4 class="m-0">{{ experience?.cargo }}</h4>
                        <h5 class="m-0 fs-13 fw-medium fc-blue mt-2">{{ experience?.empresa }}</h5>
                        <h5 *ngIf="experience.active" class="m-0 fs-13 fc-grey mt-2">
                            <i class="fa fa-calendar mr-2"></i> {{ experience?.month_start }} de {{ experience.year_start }} - até o momento
                        </h5>
                        <h5 *ngIf="!experience.active" class="m-0 fs-13 fc-grey mt-2">
                            <i class="fa fa-calendar mr-2"></i> {{ experience?.month_start }} de {{ experience.year_start }} - {{ experience?.month_end }} de {{ experience.year_end }}
                        </h5>
                        <h5 class="m-0 fs-13 fc-grey mt-2"><i class="fa fa-map-marker fc-grey mr-2"></i> {{ experience?.estado }}</h5>
                        <p class="mt-4">{{ experience?.descricao }}</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mt-3" *ngIf="data.volunteering[0]?.organizacao">
                <div class="curriculum-title">
                    <h3>Experiência de voluntariado</h3>
                </div>
                <div class="row curriculum-content">
                    <div class="col-sm-12" *ngFor="let volunteering of data?.volunteering">
                        <h4 class="m-0">{{ volunteering.funcao }}</h4>
                        <h5 class="m-0 fs-13 fw-medium fc-blue mt-2">{{ volunteering.organizacao }}</h5>
                        <h5 *ngIf="volunteering.active" class="m-0 fs-13 fc-grey mt-2"><i class="fa fa-calendar mr-2"></i> {{ volunteering?.month_start }} de {{ volunteering.year_start }} - até o momento </h5>
                        <h5 *ngIf="!volunteering.active" class="m-0 fs-13 fc-grey mt-2"><i class="fa fa-calendar mr-2"></i> {{ volunteering?.month_start }} de {{ volunteering.year_start }} - {{ volunteering?.month_end }} de {{ volunteering.year_end }} </h5>

                        <h5 class="m-0 fs-13 fc-grey mt-2">{{ volunteering.causa }}</h5>
                        <p class="mt-4"> {{ volunteering?.descricao }}</p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mt-3" *ngIf="data.formation[0].curso">
                <div class="curriculum-title">
                    <h3>Formação</h3>
                </div>
                <div class="row curriculum-content">
                    <div class="col-sm-12" *ngFor="let formation of data?.formation">
                        <div class="mt-4" *ngIf="formation?.curso != null">
                            <h4 class="m-0 fs-15 mt-2">{{ formation?.curso }}</h4>
                            <h5 class="m-0 fs-14 fw-medium fc-blue mt-2">{{ formation?.instituicao }}</h5>
                            <h5 class="m-0 fs-14 fw-medium fc-grey mt-2"><i class="fa fa-calendar mr-2"></i>{{ formation?.year_start }} - {{ formation?.year_end }}</h5>
                            <h5 class="m-0 fs-14 fw-medium fc-grey mt-2"><i class="fa fa-map-marker mr-2"></i>{{ formation?.estado }}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mt-3" *ngIf="data.languages[0].idioma">
                <div class="curriculum-title">
                    <h3>Idiomas</h3>
                </div>
                <div class="row curriculum-content" *ngFor="let language of data?.languages">
                    <div class="col-sm-2 mt-3">
                        <h5 class="m-0 fs-14 fw-medium fc-blue">{{ language?.idioma }}</h5>
                    </div>
                    <div class="col-sm-10 mt-3">
                        {{ language?.nivel }}
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mt-3" *ngIf="data.cert">
                <div class="curriculum-title">
                    <h3>Certificados</h3>
                </div>
                <div class="row curriculum-content" *ngFor="let cert of data?.cert; let i = index;">
                    <div class="col-sm-12">
                        <ul class="list-group">
                            <li class="list-group-item">
                                <a [href]="cert.file.path" target="_blank">{{ cert.title }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 mt-3" *ngIf="data.etalentData">
                <div class="curriculum-title">
                    <h3>Análise comportamental</h3>
                </div>
                <div class="row curriculum-content">
                    <div class="col-sm-12">
                        <h4><a [href]="data.etalentData.report_url" target="_blank">Ver análise</a></h4>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</ng-container>

<ng-container *ngIf="data?.length == 0">
    Este usuário não preencheu o curriculo.
</ng-container>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Fechar</button>
</mat-dialog-actions>

