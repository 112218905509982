import { AsapBaseService } from 'asap-crud';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class CompanyEducationalService extends AsapBaseService {

	constructor(public http: HttpClient) {
		super(http);
		this.basePath = "api/v2/client";
		this.entityName = "company-educational";
    }

    
    // public listCandidates(page=1) {
    //     // this.entityName = 'opportunity/list/candidates';
    //     return this.getResources({query: `page=${page}`}); 
    // }

    // public listCandidatesByVacancie(vacancieID=null, params=null) {
    //     this.entityName = 'opportunity/list/adm';
    //     return this.getResource(vacancieID, params == null ? {} : params);    
    // }

    // public listCandidatesByID(userID) {
    //     this.entityName = 'opportunity/list/user';
    //     return this.getResource(userID);    
    // }

    // public listCourses() {
    //     // this.entityName = 'opportunity/list/course/all';
    //     return this.getResources({router: 'list/course/all' });
    // }

    // public updateStatus(data) {
    //     this.entityName = 'opportunity/list/adm';
    //     return this.updateResource(data);
    // }

    // public sendMail(data) {
    //     this.entityName = 'opportunity/send/email';
    //     return this.createResource(data);
    // }
    // public sendMailInvite(data) {
    //     this.entityName = 'opportunity/send/email/invite';
    //     return this.createResource(data);
    // }

    // public deleteUser(data) {
    //     this.entityName = 'opportunity/delete/user?user_id='+data.user_id+'&opportunity_id='+data.opportunity_id;
    //     return this.deleteResource(data);
    // }

}
