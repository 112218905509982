import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';

declare var $: any;

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})

export class SpinnerComponent implements OnInit {

  // private arrayIdsCategories: any = [];
  // public arrayFeedList: any = [];
  // public form: FormGroup;
  // public formComment: FormGroup;
  // private loggedInUser: any;

 
  ngOnInit() {
    
  }

}
