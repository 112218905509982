import { AuthGuardService } from './services/auth-guard.service';
import { ForbidenComponent } from './auth-guard/forbiden.component';

import { Routes } from '@angular/router';
import { DashboardLoadingComponent } from './dashboard-loading/dashboard-loading.component';
import { LogoutComponent } from './logout.component';
import { AsapCurriculumRoutes } from 'asap-curriculum';

export const AppRoutes: Routes = [
  {
    path: 'forbiden',
    component: ForbidenComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'logout',
    component: LogoutComponent
  },

  {
    path: '',
    component: DashboardLoadingComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        canLoad: [AuthGuardService],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'candidates',
        canLoad: [AuthGuardService],
        loadChildren: () => import('./candidates/candidates.module').then(m => m.CandidatesModule)
      },
      {
        path: 'vacancies',
        canLoad: [AuthGuardService],
        loadChildren: () => import('./vacancies/vacancies.module').then(m => m.VacanciesModule)
      },
      {
        path: 'relatorio',
        canLoad: [AuthGuardService],
        loadChildren: () => import('./relatorio/relatorio.module').then(m => m.RelatorioModule)
      },

      {
        path: 'selection',
        canLoad: [AuthGuardService],
        loadChildren: () => import('./selection/selection.module').then(m => m.SelectionModule)
      },

      {
        path: 'curriculum',
        children: AsapCurriculumRoutes
      },
      {
        path: 'curriculum/:id',
        children: AsapCurriculumRoutes
      },
      // {
      //   path: '',
      //   canLoad: [AuthGuardService],
      //   loadChildren: './education/education.module#EducationModule'
      // },
      // {
      //   path: '',
      //   canLoad: [AuthGuardService],
      //   loadChildren: './reports/reports.module#ReportsModule'
      // },
      // {
      //   path: '',
      //   canLoad: [AuthGuardService],
      //   loadChildren: './helpdesk/helpdesk.module#HelpdeskModule'
      // },
      // {
      //   path: '',
      //   canLoad: [AuthGuardService],
      //   loadChildren: './settings/settings.module#SettingsModule'
      // },
      // {
      //   path: '',
      //   canLoad: [AuthGuardService],
      //   loadChildren: './selection-dashboard/selection-dashboard.module#SelectionDashboardModule'
      // },
    ]
  },
  // {
  //   path: '',
  //   redirectTo: 'dashboard',
  //   pathMatch: 'full',
  // },
  // {
  //   path: 'settings',
  //   loadChildren: './settings/settings.module#SettingsModule'
  // },
  // {
  //   path: 'profile',
  //   loadChildren: './profile/profile.module#ProfileModule'
  // },
  // {
  //   path: 'dashboard',
  //   loadChildren: './dashboard/dashboard.module#DashboardModule'
  // },
  // {
  //   path: 'vacancies',
  //   loadChildren: './vacancies/vacancies.module#VacanciesModule'
  // },
  // {
  //   path: 'candidates',
  //   loadChildren: './candidates/candidates.module#CandidatesModule'
  // },
  // {
  //   path: 'selection',
  //   loadChildren: './selection/selection.module#SelectionModule'
  // },
  // {
  //   path: 'education',
  //   loadChildren: './education/education.module#EducationModule'
  // },
  // {
  //   path: 'reports',
  //   loadChildren: './reports/reports.module#ReportsModule'
  // },
  // {
  //   path: 'helpdesk',
  //   loadChildren: './helpdesk/helpdesk.module#HelpdeskModule'
  // },
];
