import { Component } from "@angular/core";

import { CLIENT_DATA } from "./clients";

@Component({
    selector: "app-logout",
    template: "<div></div>"
})
export class LogoutComponent {


    constructor(

    ) {
        window.close();
    }

}
