import { Global, AsapBaseCRUD, AsapService } from 'asap-crud';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ViewChild, Input, Inject, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

declare var $: any;
// declare var swal: any;

@Component({
    selector: 'app-etalent-detail',
    templateUrl: './etalent-detail.component.html',
    // styleUrls: ['../curriculum.component.scss'],
    providers: [ AsapService ]
})
export class EtalentDetailComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<EtalentDetailComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        public service: AsapService,
        public global: Global
    ) {
        // super(global, service);
        // this.service.basePath = "api/v2/client";
        // this.service.entityName = "curriculum";
        // this.editBootstrap = true;
        // this.editClass = '#experiencia';
        // this.afterCreate();
        // console.log("data",this.data);
     }

    close(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        console.log("data",this.data);
    }




   
}
