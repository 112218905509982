import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'asap-crud';

@Component({
  selector: 'app-forbiden',
  templateUrl: './forbiden.component.html',
  styleUrls: ['./forbiden.component.scss']
})

export class ForbidenComponent {

    constructor(
        public router: Router,
        public global: Global
    ) {
        console.log("Logged",this.global.loggedUser())
        if (this.global.loggedUser()) {
            this.router.navigate(['/candidates']);
        }
    }


}
