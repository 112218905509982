
<nav id="sidebar" class="">
  <div class="triangle sidebarCollapse d-block d-sm-none">
    <i class='fa fa-arrow-left'></i>
  </div>

  <div class="d-none d-sm-block">
    <div class="sidebar-header">
      <img [src]="asap.dictionary.logoDark" [routerLink]="['/candidates']" class="logo">
    </div>
  </div>

  <!-- Se pessoa júridica -->
  <div *ngIf="companyData" class="sidebar-client" style="justify-content: center;">
    <!-- <label for="file-upload" class="custom-file-upload">
      <i class="material-icons fs-60">backup</i>
    </label>
    <input id="file-upload" type="file" (change)="uploadImage($event.target.files)"/> -->
    <!-- {{ companyData.picture | json }} -->
    <div class="d-none"><asap-upload #asapUpload type="file" (AddArchive)="getImage($event)"></asap-upload></div>
    <button type="button" class="btn btn-primary btn-xs" (click)="asapUpload?.openDialog()">
      <img *ngIf="companyData?.picture" [src]="companyData?.picture" class="logo">
      <i class="material-icons fs-60 d-block upload-logo">backup</i>
    </button>

  </div>

  <div class="sidebar-menu">

    <!-- Para mobile -->
    <div class="d-block d-sm-none">
      <ul class="nav-icons">
        <li *ngFor="let item of nav">
          <a routerLink="{{item.path}}">
            <i class="{{item.icon}}"></i>
          </a>
        </li>
      </ul>
      <ul class="menu nav-hidden">
        <li *ngFor="let item of menu">
          <a routerLink="{{item.path}}">
            <i class="{{item.icon}} mr-2"></i>
            {{item.name}}
          </a>
        </li>
      </ul>
    </div>

    <!-- Para desktop -->
    <div class="d-none d-sm-block">
      <ul class="menu">
        <li *ngFor="let item of menu">
          <a routerLink="{{item.path}}">
            <i class="{{item.icon}} mr-2"></i>
            {{item.name}}
          </a>
        </li>
      </ul>
    </div>

  </div>

  <div class="sidebar-footer">
    <ul class="socials">
      <li *ngFor="let item of socials">
        <a href="{{item.hiperLink}}" target="_blank">
          <i class="{{item.icon}}"></i>
        </a>
      </li>
    </ul>
    <div class="copyright">
      ©‎ 2023 - {{asap.dictionary.appName}}
    </div>
  </div>
</nav>
