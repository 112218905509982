import { Global, AsapService } from 'asap-crud';
import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-loading',
  templateUrl: './dashboard-loading.component.html',
  styleUrls: ['./dashboard-loading.component.scss']
})

export class DashboardLoadingComponent {

    constructor(
        public global: Global      
    ) {
    
    }

  
}
