<h2 mat-dialog-title>Arquivos</h2>
<mat-dialog-content class="mat-typography">
  
    <ng-container *ngFor="let prod of productions">
        <a *ngFor="let answer of prod.value.file" target="_blank" [href]="answer.path"[download]="answer.name" class="list-group-item list-group-item-action" >
            <i [class]="mimeToIcon(answer.type)" style="margin-right: 5px;"></i> {{ answer.name }} 
        </a>
      </ng-container>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>




