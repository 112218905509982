import { AuthService } from './services/auth.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { AsapService, Global } from 'asap-crud';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent {

    CLIENT = 'realiza';

    constructor(
        public global: Global,
        public service: AsapService,
        public route: ActivatedRoute,
        public router: Router,
        public auth: AuthService
    ) {
        console.log("[Global]", this.global);
        this.global.setSessionData('backURL', document.referrer);
    }

    ngOnInit(): void {
        this.router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event)
        });
    }

    public resetStorage() {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('apiURL', environment.apiURL);
    }

    public getQueryParams(route) {
        let uri = route.split("?");
        if (uri.length == 1) {
            return null;
        }
        else {
            let result: any = {}
            uri = uri[1];
            uri = uri.split("&");
            for (let params of uri) {
                let paramIdx = params.indexOf("=");
                let key = params.substring(0, paramIdx);
                let value = params.substring(paramIdx + 1);
                result[key] = decodeURIComponent(value);
            }
            return result;
        }
    }

    public navigationInterceptor(event: RouterEvent): void {

        if (event instanceof NavigationStart) {
            this.auth.params = this.getQueryParams(event.url);
            if (this.auth.params && this.auth.params.token) {
                this.resetStorage();
                this.auth.setSessionToken({ token: this.auth.params.token }).then(
                    (response) => {
                        localStorage.setItem('client', this.global.loggedUser().client_id);
                        let url = event.url.split('?')[0];
                        if (url == '/') {
                            this.router.navigate(['/vacancies']);
                        }
                    },
                    (error) => {
                        if (event.url != '/forbiden') {
                            this.router.navigate(['/forbiden']);
                        }
                    }
                );
            }
            else if (this.global.loggedUser()) {
                if (!this.auth.theme.themeLoaded) {
                    this.auth.setClientTheme(this.global.loggedUser().client_id).then();
                }
                if (event.url == '/') {
                    this.router.navigate(['/vacancies']);
                }
            }
            else {
                this.auth.params = this.getQueryParams(event.url);
                if ((!this.auth.params) || (!this.auth.params.token)) {
                    if (event.url != '/forbiden') {
                        this.router.navigate(['/forbiden']);
                    }
                }
            }
        }

        if (event instanceof NavigationEnd) {

        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {

        }

        if (event instanceof NavigationError) {

        }
    }

}
