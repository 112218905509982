import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global, AsapBaseService } from 'asap-crud';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class ClienteService extends AsapBaseService {

    constructor(
        public http: HttpClient,
        public global: Global,
        public router: Router
    ) {
        super(http);
        this.basePath = 'api/v2';
        this.entityName = 'admin'
    }


    getColorByHost(host:string): Observable<any> {
        let data = {
            url: host
        }
        return this.createResource(data,{router:'color/host'});
    }

    getColorByID(id:string) {
        return this.getResources({router:'color/' + id});
    }

}
