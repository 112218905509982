
  <div class="creation-container" *ngIf="types?.file">
    <div class="creation-preview">
      <img [src]="types?.file?.path" *ngIf="getExtensao(types?.file?.type) === 'imagem'" />
      <div class="creation-video-wrapper" *ngIf="getExtensao(types?.file?.type) === 'video'">
        <video width="100%" height="200" controls>
          <source [src]="types?.file?.path" type="video/mp4">
          <source [src]="types?.file?.path" type="video/ogg">
          Your browser does not support the video tag.
        </video>
      </div>
      <div class="creation-pdf-wrapper" *ngIf="getExtensao(types?.file?.type) === 'pdf'">
        <iframe [src]="types?.file?.preview" frameborder="0" height="300px" width="100%"></iframe>
      </div>
    </div>
    <div class="creation-info" title="{{ types?.title }}">
      <span class="creation-icon">
        <i class="fa fa-image text-primary" aria-hidden="true" *ngIf="getExtensao(types?.file?.type) === 'imagem'"></i>
        <i class="fa fa-play text-primary" aria-hidden="true" *ngIf="getExtensao(types?.file?.type) === 'video'"></i>
        <i class="fa fa-file-pdf-o text-primary" aria-hidden="true" *ngIf="getExtensao(types?.file?.type) === 'pdf'"></i>
        <i class="fa fa-file text-primary" aria-hidden="true" *ngIf="getExtensao(types?.file?.type) === 'semtipo'"></i>
      </span>
      {{ types?.title }}
    </div>
  </div>

  <div class="creation-container" *ngIf="!types?.file">
      <div class="creation-preview">
        <div class="creation-pdf-wrapper" *ngIf="getType(types?.name) == 'pdf'">
          <iframe [src]="types.preview" frameborder="0" height="300px" width="100%"></iframe>
        </div>

        <span class="type-video" *ngIf="getType(types?.name) == 'video'">
          <b>{{ types?.name }}</b>
        </span>

        <img *ngIf="getType(types?.name) == 'image'" [src]="types?.path" alt="">

        <video *ngIf="getType(types?.name) == 'video'" width="190" height="190" controls>
          <source [src]="types?.path" type="video/mp4">
          <source [src]="types?.path" type="video/ogg">
          Your browser does not support the video tag.
        </video>
      </div>

      <div class="creation-info" title="{{ types?.name }}">
        <span class="creation-icon">
          <i class="fa fa-image text-primary" aria-hidden="true" *ngIf="getType(types?.name) === 'image'"></i>
          <i class="fa fa-play text-primary" aria-hidden="true" *ngIf="getType(types?.name) === 'video'"></i>
          <i class="fa fa-file-pdf-o text-primary" aria-hidden="true" *ngIf="getType(types?.name) === 'pdf'"></i>
        </span>
        {{ types?.name }}
      </div>

    </div>