import { Global, AsapBaseCRUD, AsapService } from 'asap-crud';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ViewChild, Input, Inject, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { EtalentDetailComponent } from './etalent-detail/etalent-detail.component';

declare var $: any;

@Component({
    selector: 'app-cadidates-curriculum',
    templateUrl: './candidates-curriculum.component.html',
    providers: [ AsapService ]
})
export class CandidatesCurriculumComponent implements OnInit {

    public experience:any = [];

    constructor(
        public dialogRef: MatDialogRef<CandidatesCurriculumComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        public service: AsapService,
        public global: Global,
        public dialog: MatDialog
    ) {
        console.log("data",this.data);
     }

    close(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
    }

    public openDialogEtalent(dados) {
        // let height = "200px";
        // let width = "400px";

        // if (dados.folks) {
        //     height = "700px";
        //     width = "1100px";
        // }
        const dialogRef = this.dialog.open(EtalentDetailComponent, {
            // height: height,
            // width: width,
            // panelClass: 'curriculum',
            data: dados
        });
    
        // dialogRef.afterClosed().subscribe(result => {
        //     if (result) {
        //         this.getDataCurriculum();
        //     }
        // });
    }


   
}
