import { Component, Input } from '@angular/core';
import { SafeResourceUrl, DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-candidates-productions-types',
  templateUrl: './candidates-productions-types.component.html',
  styleUrls: ['./candidates-productions-types.component.css']
})

export class CandidatesProductionsTypesComponent {

  @Input() types;
  public checkType;


  getExtensao(extensao: string) {
    if (extensao === 'image/jpeg' || extensao === 'image/png' || extensao === 'image/gif') {
      return 'imagem';
    }
    if (extensao === 'video/mp4') {
      return 'video';
    }
    if (extensao === 'video/mov') {
      return 'video';
    }
    if (extensao === 'application/pdf') {
      return 'pdf';
    }
    return 'semtipo';
  }

  getType(name) {
    if (name !== undefined) {
      const extention = name.split('.')[1].toLowerCase();
      if (extention === 'jpg' || extention === 'png' || extention === 'jpeg') {
        return 'image';
      } else if (extention === 'mp4') {
        return 'video';
      } else if (extention === 'pdf') {
        return 'pdf';
      }
    }
  }

}
