
<nav class="navbar navbar-expand-lg shadow-sm">
  <div class="container-fluid">
    <!-- Toggle sidebar desktop -->
    <a class="btn-toggler sidebarCollapse d-none d-sm-block">
      <span id="btn-h"><i class='fa fa-arrow-left'></i></span>
    </a>
    <!-- Toggle sidebar mobile -->
    <a class="btn-toggler sidebarCollapse d-block d-sm-none">
      <span><i class='fa fa-arrow-right'></i></span>
    </a>
    <div class="logo d-block d-sm-none"></div>
    <div class="collapse navbar-collapse d-none d-sm-block" style="justify-content: flex-end;"  id="mainNavbar">
      <a class="btn btn-sm btn-primary" target="_blank" [href]="[global.getSessionData('backURL')]"><i class="fa fa-globe mr-2"></i>Acessar Plataforma</a>
      <ul class="navbar-nav">
        <li class="nav-item" *ngFor="let item of nav">
          <a class="nav-link" *ngIf="item.tooltip != 'Perfil'" routerLink="{{item.path}}" #tooltip="matTooltip" matTooltipClass="tooltip-custom" matTooltip="{{item.tooltip}}" matTooltipPosition="above">
            <i class="{{item.icon}}"></i>
          </a>
          <!-- <a class="nav-link" *ngIf="item.tooltip == 'Perfil'" href="https://realiza.b2c.hmgasap.com.br/my-page" target="_blank" #tooltip="matTooltip" matTooltipClass="tooltip-custom" matTooltip="{{item.tooltip}}" matTooltipPosition="above">
            <i class="{{item.icon}}"></i>
          </a> -->
        </li>
      </ul>
    </div>
  </div>
</nav>
