<div class="wrapper" *ngIf="global.loggedUser()">
    <app-sidebar></app-sidebar>
    <div id="content">
      <app-navbar></app-navbar>
      <div class="_container">
        <router-outlet></router-outlet>
      </div>
    </div>
</div>

<div class="dashboard-waiting" *ngIf="!global.loggedUser()">
    <div class="text-center">
        <i class="fa fa-spinner fa-spin fa-3x"></i>        
        <p class="text fw-100">Carregando Plataforma...</p>
    </div>
</div>
  