import { ForbidenComponent } from './auth-guard/forbiden.component';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { SharedModule } from 'src/app/shared/shared.module';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { NotificationsService, SimpleNotificationsModule } from 'angular2-notifications';
import { AsapCrudModule, Global } from 'asap-crud';
import { DashboardLoadingComponent } from './dashboard-loading/dashboard-loading.component';
import { LogoutComponent } from './logout.component';
import { ClienteService } from './services/client.service';
import { ClientInterceptor } from './@core/interceptors/client-interceptor';
import { AsapClientPipe } from './@core/pipes/asap-client.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AsapCurriculumModule } from 'asap-curriculum';
import { Ng2IziToastModule } from 'ng2-izitoast';

declare var $: any;

registerLocaleData(localePt);

@NgModule({
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
        DragDropModule,
        SharedModule,
        TranslateModule.forRoot({}),
        AsapCrudModule,
        AsapCurriculumModule,
        Ng2IziToastModule,
        // FirebaseModule
    ],
    declarations: [
        AppComponent,
        ForbidenComponent,
        DashboardLoadingComponent,
        LogoutComponent,
        AsapClientPipe,
    ],
    exports: [
        AsapCrudModule
    ],
    providers: [
        Global,
        ClienteService,
        AuthService,
        AuthGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ClientInterceptor,
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(

    ) {

    }
}
