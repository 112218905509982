import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-production',
  templateUrl: './modal-production.component.html',
  styleUrls: ['./modal-production.component.scss']
})
export class ModalProductionComponent implements OnInit {


  constructor(
    @Inject(MAT_DIALOG_DATA) public productions: any
  ) { }

  ngOnInit(): void {

  }

  mimeToIcon(mimetype: string) {
    if (mimetype === 'application/pdf') {
      return 'fa fa-file';
    }
    if (
      mimetype === 'image/png' ||
      mimetype === 'image/jpg' ||
      mimetype === 'image/jpeg' ||
      mimetype === 'image/gif'
    ) {
      return 'fa fa-image';
    }
    return 'fa fa-file';
  }

}
