import { CompanyEducationalService } from './../../services/company-educational.service';
import { Global } from 'asap-crud';
import { Component } from '@angular/core';
import { AsapClientPipe } from 'src/app/@core/pipes/asap-client.pipe';

declare const $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  providers: [CompanyEducationalService]
})

export class SidebarComponent {

  public user;
  public companyData;
  public asap = AsapClientPipe;

  public menu = [
    // { icon: 'fa fa-dashboard', path: '/dashboard', name: 'Meu dashboard'},
    { icon: 'fa fa-newspaper-o', path: '/vacancies', name: 'Vagas' },
    { icon: 'fa fa-users', path: '/candidates', name: 'Banco de talentos' },
    { icon: 'fa fa-file-text-o', path: '/relatorio', name: 'Relatórios' },

    // { icon: 'fa fa-sliders', path: '/selection', name: 'Processo seletivo' },
    // { icon: 'fa fa-graduation-cap', path: '/education', name: 'Educação corporativa'},
    // { icon: 'fa fa-file-text-o', path: '/selection-dashboard', name: 'Dashboard de seleção'},
    // { icon: 'fa fa-line-chart', path: '/reports', name: 'Relatórios'},
  ];

  public nav = [
    { path: '/profile', icon: 'fa fa-user' },
    { path: '/helpdesk', icon: 'fa fa-bell' },
    { path: '/settings', icon: 'fa fa-cog' }
  ];

  public socials = [
    { icon: 'fa fa-facebook', hiperLink: 'https://www.facebook.com' },
    { icon: 'fa fa-linkedin', hiperLink: 'https://www.linkedin.com' },
    { icon: 'fa fa-instagram', hiperLink: 'https://www.instagram.com' },
    { icon: 'fa fa-youtube', hiperLink: 'https://www.youtube.com' }
  ];

  constructor(
    public global: Global,
    public companyEducationalService: CompanyEducationalService
  ) {
    this.user = this.global.loggedUser();
    this.getCompanyData(this.user.id);

    if (AsapClientPipe.dictionary) {
      this.socials[0].hiperLink = AsapClientPipe.dictionary.footerFacebook;
      this.socials[1].hiperLink = AsapClientPipe.dictionary.footerLinkedin;
      this.socials[2].hiperLink = AsapClientPipe.dictionary.footerInstagram;
      this.socials[3].hiperLink = AsapClientPipe.dictionary.footerYoutube;
    }
  }

  // public uploadImage(file) {
  //   let _file = file.item(0);
  //   console.log('event->', _file);
  //   if (_file.size <= 200000000) {
  //     // this.loadingScorm = true;
  //     // this.global.startLoading('Estamos processando seu Pacote SCORM, aguarde...');
  //     this.trailService.uploadScorm(file.item(0)).subscribe(
  //       (response: any) => {
  //         if (response.success) {
  //           // this.loadingScorm = false;
  //           // this.scormName = file.item(0).name;
  //           // this.scormFile = response.url;
  //           // console.log(this.scormName);
  //         }
  //         // this.global.stopLoading();
  //       }
  //       , error => {
  //         // this.global.stopLoading();
  //         Swal.fire({
  //           title: 'Ops',
  //           icon: 'error',
  //           text: 'Tivemos um problema ao processar sua imagem, tente novamente em alguns minutos.'
  //         })
  //       });
  //   }
  //   else {
  //     Swal.fire({
  //       title: 'Ops',
  //       icon: 'warning',
  //       text: 'A imagem que você está enviando excede o tamanho máximo aceito que é de 200mb.'
  //     })
  //   }

  // }

  public getImage(image) {
    console.log("image", image);
    if (image.path) {
      if (this.companyData.user_id) {
        let data = {
          // user_id: this.companyData.user_id,
          // client_id: this.companyData.client_id,
          picture: image.path,
          name: this.companyData.name,
        };
        this.companyEducationalService.updateResource(data, { router: this.companyData.user_id }).subscribe(response => {
          console.log("kkkkkk", response);
          this.getCompanyData(this.companyData.user_id);
        });
      }
      else {
        let data = {
          user_id: this.user.id,
          client_id: this.user.client_id,
          picture: image.path,
          name: this.user.name,
        };
        this.companyEducationalService.createResource(data).subscribe(response => {
          console.log("kkk", response);
          this.getCompanyData(this.user.id);
        });
      }
    }
  }

  ngOnInit() {

    $(document).ready(function () {

      $('.sidebarCollapse, .nav-icons, .nav-hidden').on('click', function () {
        $('#sidebar, #content, .navbar').toggleClass('active');
      });

      $(document).on('click', '#btn-h', function () {
        $(this).attr("id", "btn-v").html("<i class='fa fa-arrow-right'></i>");
      });

      $(document).on('click', '#btn-v', function () {
        $(this).attr("id", "btn-h").html("<i class='fa fa-arrow-left'></i>");
      });
    });
  }

  public getCompanyData(userID) {
    // this.companyEducationalService.getResource(userID).subscribe(response => {
    //   this.companyData = response;
    //   console.log('response',response);
    // });
  }

}
