import { SpinnerComponent } from './spinner/spinner.component';
import { NgModule }from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';
import { AsapUploadModule } from 'asap-upload';

import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { OwlModule } from 'ngx-owl-carousel';
import { NgxMaskModule } from 'ngx-mask';
import { CandidatesCurriculumComponent } from '../candidates/curriculum/candidates-curriculum.component';
import { QuillModule } from 'ngx-quill';
import { EtalentDetailComponent } from '../candidates/curriculum/etalent-detail/etalent-detail.component';
import { CandidatesProductionsComponent } from '../vacancies/list-candidates/candidates-productions/candidates-productions.component';
import { CandidatesProductionsTypesComponent } from '../vacancies/list-candidates/candidates-productions/types/candidates-productions-types.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    AsapUploadModule,
    OwlModule,
    NgxSkeletonLoaderModule,
    NgxMaskModule.forRoot(),
    QuillModule.forRoot()
  ],
  declarations: [
    NavbarComponent,
    SidebarComponent,
    SpinnerComponent,
    CandidatesCurriculumComponent,
    EtalentDetailComponent,
    CandidatesProductionsComponent,
    CandidatesProductionsTypesComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NavbarComponent,
    SidebarComponent,
    SpinnerComponent,
    AsapUploadModule,
    OwlModule,
    NgxSkeletonLoaderModule,
    CandidatesCurriculumComponent,
    EtalentDetailComponent,
    CandidatesProductionsComponent,
    CandidatesProductionsTypesComponent
  ],
})

export class SharedModule { }
