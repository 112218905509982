<ng-container *ngIf="data">

    <h2 mat-dialog-title class="text-center">Mais detalhes sobre o perfil comportamental do candidato</h2>
        <mat-dialog-content class="mat-typography">
            <div class="row">

                <div class="col-sm-12">                    
                    <div class="row curriculum-content"> 
                        <div class="col-sm-12 mt-3" *ngFor="let analyse of data.executive_analysis.slice().reverse()">
                            <p><strong>{{ analyse['Title'] }}</strong></p>   
                            <h4>{{ analyse['Description'] }}</h4>                         
                        </div>
                        <!-- <div class="col-sm-12 mt-3">
                            <p><strong>Descrição do seu talento</strong></p>
                            <h4>{{ data.talent_info['Description'] }}</h4>                            
                        </div>
                        <div class="col-sm-12 mt-3">
                            <p><strong>Movitação</strong></p>
                            <h4>{{ data.talent_info['Motivation'] }}</h4>                            
                        </div>
                        <div class="col-sm-12 mt-3">
                            <p><strong>Sumário</strong></p>
                            <h4>{{ data.talent_info['Summary'] }}</h4>                            
                        </div> -->
                        <!-- <div class="col-sm-12 mt-3">
                            <p><strong>Reconheça seu talento</strong></p>
                            <h4>{{ data.behavioral_analysis['Reconheça seu talento'] }}</h4>                            
                        </div>
                        <div class="col-sm-12 mt-3" *ngIf="data.behavioral_analysis['Dica para entrevistas de emprego\r: Paciência']">
                            <p><strong>Dica para entrevistas de emprego : Paciência</strong></p>
                            <h4>{{ data.behavioral_analysis["Dica para entrevistas de emprego\r: Paciência"] }}</h4>
                        </div>
                        <div class="col-sm-12 mt-3" *ngIf="data.behavioral_analysis['Dica para entrevistas de emprego\r: Conexão']">
                            <p><strong>Dica para entrevistas de emprego : Conexão</strong></p>
                            <h4>{{ data.behavioral_analysis["Dica para entrevistas de emprego\r: Conexão"] }}</h4>
                        </div>
                        <div class="col-sm-12 mt-3" *ngIf="data.behavioral_analysis['Dica para entrevistas de emprego\r: Persistência']">
                            <p><strong>Dica para entrevistas de emprego : Persistência</strong></p>
                            <h4>{{ data.behavioral_analysis["Dica para entrevistas de emprego\r: Persistência"] }}</h4>
                        </div>
                        <div class="col-sm-12 mt-3" *ngIf="data.behavioral_analysis['Dica para entrevistas de emprego\r: Consideração']">
                            <p><strong>Dica para entrevistas de emprego : Consideração</strong></p>
                            <h4>{{ data.behavioral_analysis["Dica para entrevistas de emprego\r: Consideração"] }}</h4>
                        </div>
                        <div class="col-sm-12 mt-3" *ngIf="data.behavioral_analysis['Dica para entrevistas de emprego\r: Exatidão']">
                            <p><strong>Dica para entrevistas de emprego : Exatidão</strong></p>
                            <h4>{{ data.behavioral_analysis["Dica para entrevistas de emprego\r: Exatidão"] }}</h4>
                        </div>
                        <div class="col-sm-12" *ngIf="data.behavioral_analysis['Dica para entrevistas de emprego\r: Determinação']">
                            <p><strong>Dica para entrevistas de emprego : Determinação</strong></p>
                            <h4>{{ data.behavioral_analysis["Dica para entrevistas de emprego\r: Determinação"] }}</h4>
                        </div>
                        <div class="col-sm-12" *ngIf="data.behavioral_analysis['Dica para entrevistas de emprego\r: Perceptividade']">
                            <p><strong>Dica para entrevistas de emprego : Perceptividade</strong></p>
                            <h4>{{ data.behavioral_analysis["Dica para entrevistas de emprego\r: Perceptividade"] }}</h4>
                        </div>
                        <div class="col-sm-12" *ngIf="data.behavioral_analysis['Dica para entrevistas de emprego\r: Automotivação']">
                            <p><strong>Dica para entrevistas de emprego : Automotivação</strong></p>
                            <h4>{{ data.behavioral_analysis["Dica para entrevistas de emprego\r: Automotivação"] }}</h4>
                        </div>
                        <div class="col-sm-12" *ngIf="data.behavioral_analysis['Dica para entrevistas de emprego\r: Sociabilidade']">
                            <p><strong>Dica para entrevistas de emprego : Sociabilidade</strong></p>
                            <h4>{{ data.behavioral_analysis["Dica para entrevistas de emprego\r: Sociabilidade"] }}</h4>
                        </div> -->
                    </div>
                </div>      
            </div>    
        </mat-dialog-content>
    </ng-container>
    
    <ng-container *ngIf="data?.length == 0">
        Este usuário não preencheu o curriculo.
    </ng-container>
    <ng-container *ngIf="!data?.executive_analysis">
        <p>Este usuário possui o cadastro da etalent desatualizado!</p>
        <!-- <p>Por favor, entre em contato com o suporte.</p> -->
    </ng-container>
    
    <mat-dialog-actions align="end">
        <a *ngIf="data.managament_report" class="mr-2" [href]="data.managament_report" target="_black">Ver relatório completo</a>
        <button mat-button mat-dialog-close>Fechar</button>
    </mat-dialog-actions>
    
    