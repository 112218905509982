<div *ngIf="global.loading || !auth.theme.themeLoaded" class="admin-loader">
    <div class="loader">
        <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
        </svg>
    </div>
    <p class="text text-bold">{{global.loadingText}}</p>
</div>
<div [class.blured]="global.loading">
    <router-outlet *ngIf="auth.theme.themeLoaded"></router-outlet>
</div>