<ng-container *ngIf="productions && productions.length">
  <div *ngIf="countProduction(productions) == 0">
    <p>Esse jovem ainda não possui nenhuma produção.</p>
  </div>
  <p class="job-value mb-3"><b>Produções:</b> {{ countProduction(productions) }}</p>
  <ng-container *ngFor="let prod of productions.slice(0, 3)">
    <a *ngFor="let answer of prod.value.file" target="_blank" [href]="answer.path"[download]="answer.name" class="list-group-item list-group-item-action" >
        <i [class]="mimeToIcon(answer.type)" style="margin-right: 5px;"></i> {{ answer.name }} 
    </a>
  </ng-container>
  <div style="display: flex; justify-content: center; align-items: center;">
    <button mat-button color="primary" (click)="show(productions)" >Ver mais</button>
  </div>
</ng-container>



  

  

