import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeResourceUrl, DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { OwlCarousel } from 'ngx-owl-carousel';
import { ModalProductionComponent } from './modal-production/modal-production.component';


@Component({
    selector: 'app-candidates-productions',
    templateUrl: './candidates-productions.component.html',
    styleUrls: ['../../vacancies.component.scss'],
})

export class CandidatesProductionsComponent {

    @Input() productions;
    @ViewChild('owlProductions') owlProductions: OwlCarousel;
    @Input() questions;
 

    options = {
        center: false,
        loop: false,
        rewind: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        URLhashListener: true,
        dots: false,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: false,
                margin: 15,
            },
            1024: {
                items: 1,
                nav: false,
                margin: 0,
            }
        }
      };

    constructor(
        public sanitizer: DomSanitizer,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        const data = this.productions;
        if (this.productions) {
            this.productions.map(file => {
                file.value.file.map(f => {
                    if (f.file) {
                        f.file.preview = this.getSafeUrl(f.file.path);
                    } else {
                        f.preview = this.getSafeUrl(f.path)
                    }
                })
            });
            this.productions = data;
        }
    }

    mimeToIcon(mimetype: string) {
        if (mimetype === 'application/pdf') {
          return 'fa fa-file';
        }
        if (
          mimetype === 'image/png' ||
          mimetype === 'image/jpg' ||
          mimetype === 'image/jpeg' ||
          mimetype === 'image/gif'
        ) {
          return 'fa fa-image';
        }
        return 'fa fa-file';
      }


    getSafeUrl(url: string): SafeResourceUrl {
        const preview = 'https://docs.google.com/viewer?url=' + encodeURIComponent(url) + '&embedded=true';
        return this.sanitizer.bypassSecurityTrustResourceUrl(preview);
    }

    countProduction(production) {
        let count = 0;
        if (production && production.length) {
            for (const prod of production) {
                count += prod.value.file.length;
            }
        }
        return count;
    }

    show(productions) {
         this.dialog.open(ModalProductionComponent, {
         data: productions
        });
      }
}
