import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'asapClient'
})
export class AsapClientPipe implements PipeTransform {

  public static loaded = false;

  public static dictionary: any = {
    clientName: 'ASAP',
    appName: 'Rede ASAP',
    appNameArticle: 'a',
    appNameInArticle: 'na',
    appNamePreposition: 'da',
    appLogoWhite: '',
    appLogoBlack: '',
    bgClient: '#fafafa',
    bgNavbar: '#000',
    linkNavbar: '#fff',
    bgSidebar: '#000',
    linkSidebar: '#fff',
    primaryColor: '#333',
    secondaryColor: '#3C4858',
    primaryColorHover: '#444',
    primaryBoxShadow: 'rgba(0,0,0,.5)',
    primaryOutlineBoxShadow: 'rgba(255,255,255,0.5)',
    favicon: '',
    portalPlanSlogan: 'Entusiasta para você!',
    portalTitle: 'Bem vindo à',
    portalName: 'Rede Asap!',
    portalSlogan: 'Aqui entra o subtítulo (slogan) do portal.',
    schoolTitle: 'Bem vindo à',
    schoolName: 'Escola Asap!',
    schoolSlogan: 'Aqui entra o subtítulo (slogan) da escola.',
    marketTitle: 'Bem vindo ao',
    marketName: 'E-commerce Asap!',
    marketSlogan: 'Aqui entra o subtítulo (slogan) do e-commerce.',
    assessmentActive: false,
    showNavBarAsIcons: true,
    firebasePrefix: 'fitnesslink',
    firebase: {
      apiKey: "AIzaSyDa8JrO-Cp_4RpY21-xj9GL2z5EQKOCx_M",
      authDomain: "realiza-5088b.firebaseapp.com",
      databaseURL: "https://realiza-5088b.firebaseio.com",
      messagingSenderId: "844301755690",
      projectId: "realiza-5088b",
      storageBucket: "realiza-5088b.appspot.com"
    },
    supplementaryDataRequired: false,
    sdTitleAlign: 'center',
    sdTitleSize: 32,
    sdTitleColor: '#222222',
    sdTitleText: 'Cadastro complementar',
    sdSubtitleAlign: 'center',
    sdSubtitleSize: 18,
    sdSubtitleColor: '#BBBBBB',
    sdSubtitleText: 'Complete o seu cadastro com os dados complementares do seu acesso',
    navbarPlataformsDisplay: true,
    navbarTransparent: false,
    navbarHelpdeskStyle: 'icon',
    navbarPagesItems: []
  };

  constructor(
    public sanitization: DomSanitizer
  ) {

  }

  public static setDictionary(dic) {
    for (const key in dic) {
      AsapClientPipe.dictionary[key] = dic[key];
    }
  }

  transform(value: any, type?: any): any {
    let result = value;

    console.log('[AsapClientPipe] Params:', value, type);

    if (AsapClientPipe.dictionary[value] != undefined) {
      result = AsapClientPipe.dictionary[value];
    }

    if (type === 'url') {
      result = this.sanitization.bypassSecurityTrustStyle('url("' + result + '")');
    }

    return result;
  }

}
