import { Component, OnInit } from '@angular/core';
import { Global } from 'asap-crud';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})

export class NavbarComponent implements OnInit {

  public static firebase;
  public db;

  public nav = [
    // { path: '/helpdesk', icon: 'fa fa-bullhorn', tooltip: 'Help desk' },
    // { path: '/settings', icon: 'fa fa-cogs', tooltip: 'Configurações' },
    // { path: '/profile', icon: 'fa fa-user-circle', tooltip: 'Perfil' }
  ]

  constructor(public global: Global) {
    
  }

  ngOnInit(): void {
    const interval = setInterval(() => {
      if (NavbarComponent.firebase) {
        // console.log('[Load NavbarComponent Firebase] ->', Date.now());
        this.db = NavbarComponent.firebase;
        // this.getNotifications();
        clearInterval(interval);
      } else {
        // console.log('[Waiting Load NavbarComponent Firebase] ->', Date.now());
      }
    }, 1000);
  }
}
